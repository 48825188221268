import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'gatsby-plugin-intl'
import { Row, Col } from 'react-simple-flex-grid'
import { graphql } from 'gatsby'

import { NavbarOnly } from '_templates'
import { Container, Text } from '_atoms'
import { CookieConsentBar } from '_molecules'
import { SEO, LestChatSection } from '_organisms'
// footer
import FacebookIcon from '_images/svgs/facebook.inline.svg'
import LinkedinIcon from '_images/svgs/linkedin.inline.svg'
import TwitterIcon from '_images/svgs/twitter.inline.svg'
import InstagramIcon from '_images/svgs/instagram.inline.svg'

import styles from './styles.module.css'

const ContactPage = ({ data }) => {
  const { contactTitle, contactDescription, contactThumbnail } = data.contentfulPageMetatags

  return (
    <NavbarOnly>
      <SEO
        title={contactTitle || 'Web &amp; Mobile App Design and Development Company'}
        description={contactDescription || 'Web &amp; Mobile App Design and Development Company'}
        thumbnail={contactThumbnail.file.url}
      />

      <LestChatSection />
      <CookieConsentBar />

      {!data.hideFooter && (
        <footer className={styles.footer}>
          <Container>
            <Row className={styles.footerRow}>
              <Col xs={12} sm={12} md={5} lg={5} className={styles.footerLinks}>
                <div className={styles.footerNetworks}>
                  <a
                    href="https://www.facebook.com/cheesecakelabs"
                    className={styles.footerNetworksLink}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <FacebookIcon />
                  </a>
                  <a
                    href="https://www.linkedin.com/company/cheesecake-labs/"
                    className={styles.footerNetworksLink}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <LinkedinIcon />
                  </a>
                  <a
                    href="https://twitter.com/cheesecakelabs"
                    className={styles.footerNetworksLink}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <TwitterIcon />
                  </a>
                  <a
                    href="https://www.instagram.com/cheesecakelabs/"
                    className={styles.footerNetworksLink}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <InstagramIcon />
                  </a>
                </div>
              </Col>
              <Col xs={12} sm={12} md={7} lg={7}>
                <Text size="24" color="white" className={styles.footerTitle}>
                  <FormattedMessage id="contact.footer" />
                  <span className={styles.squared}>?</span>
                </Text>

                <Text size="24" color="white" bold className={styles.footerVirtualAddresses}>
                  (415) 766 8860
                  <br />
                  <a href="mailto:info@cheesecakelabs.com">info@cheesecakelabs.com</a>
                </Text>

                <Row className={styles.footerAddresses}>
                  <Col xs={12} sm={6} md={6} lg={6}>
                    <Text size="20" color="primary" bold>
                      <FormattedMessage id="footer.sanFrancisco" />
                    </Text>
                    <Text size="16" color="white" className={styles.footerAddressesNumber}>
                      415 766 8860
                    </Text>
                    <Text size="14" color="white" className={styles.footerAddressesStreet}>
                      237 Kearny Street #9055
                    </Text>
                  </Col>
                  <Col xs={12} sm={6} md={6} lg={6} className={styles.footerAddressesItem}>
                    <Text size="20" color="primary" bold>
                      <FormattedMessage id="footer.florianopolis" />
                    </Text>
                    <Text size="16" color="white" className={styles.footerAddressesNumber}>
                      +55 (48) 3206-5246
                    </Text>
                    <Text size="14" color="white" className={styles.footerAddressesStreet}>
                      Av. Des. Vitor Lima 260 - 10th floor
                    </Text>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </footer>
      )}
    </NavbarOnly>
  )
}

export const query = graphql`
  query ContactPageContent($locale: String) {
    contentfulPageMetatags(fields: { localeKey: { eq: $locale } }) {
      contactDescription
      contactTitle
      contactThumbnail {
        file {
          url
        }
      }
    }
  }
`

ContactPage.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
}

export default ContactPage
